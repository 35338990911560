import React from 'react';

const includedFeatures = [
  'No account required',
  'Local and secure storage',
  'Fast access to saved snippets',
  'Completely free forever',
];

const SingleCardPricing = () => {
  return (
    <section id="pricing" className="...">
      <div className="bg-white py-24 sm:py-32">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div className="max-w-4xl mx-auto sm:text-center">
            <h2 className="text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
              Simple, Transparent, and Free
            </h2>
            <p className="mx-auto mt-6 max-w-2xl text-lg font-medium text-gray-500 sm:text-xl/8">
              Sniply is your free, hassle-free clipboard manager for all your snippets. No ads, no costs—just a reliable, private tool to store and manage what you copy.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-3xl font-semibold tracking-tight text-gray-900">
                Always Free, No Strings Attached
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Sniply is designed to be a quick, reliable tool to save what you copy—forever free and ready whenever you need it.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <svg className="h-6 w-5 flex-none text-indigo-600" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">100% Free, Forever</p>
                  <p className="mt-6 text-sm text-gray-600">
                    No payments, no subscriptions—just install and start using.
                  </p>
                  <a href="https://chromewebstore.google.com/detail/sniply/kjfkgjomikoiokdjjlcgkjgalnmhpihf" className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Start Using Sniply
                  </a>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Enjoy a powerful tool without ever needing to pay.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleCardPricing;